export default defineNuxtRouteMiddleware ( ( to, from ) =>
{
	const me = useState<User> ( 'me' ).value

	if ( !me )
	{
		return navigateTo ( '/log_in' )
	}

	if ( !me.isAdmin )
	{
		return navigateTo ( '/' )
	}

} )
